import { ERedirectUI } from 'data/types/redirect.types';
import { RoutesPaths } from 'routes/Routes.types';

export type TRedirectMapperProps = {
  url: string;
  private: boolean;
};
export const redirectsMapper: {
  [key in ERedirectUI]: TRedirectMapperProps;
} = {
  [ERedirectUI.SESSION_DETAILS]: {
    private: false,
    url: `${RoutesPaths.SESSIONS_ATTENDANCE_LIST}`,
  },
  [ERedirectUI.WORKSHOP_REGISTRANTS]: {
    private: false,
    url: `${RoutesPaths.ALL_ATTENDANCE_LIST}`,
  },
  [ERedirectUI.WORKSHOP_CHILD_CARE_REQUIREMENTS]: {
    private: false,
    url: `${RoutesPaths.CHILDCARE_LIST}`,
  },
};
