import { Outlet } from 'react-router-dom';
import { SContent, SLayoutWrapper, SMainContent } from './Layout.styles';

const Layout = () => {
  return (
    <SLayoutWrapper>
      <SContent>
        <SMainContent>
          <Outlet />
        </SMainContent>
      </SContent>
    </SLayoutWrapper>
  );
};

export default Layout;
