export enum ERedirectDB {
  SESSION_DETAILS = 'showSessionDetails',
  WORKSHOP_REGISTRANTS = 'showWorkshopRegistrants',
  WORKSHOP_CHILD_CARE_REQUIREMENTS = 'showWorkshopChildcareRequirements',
}

export enum ERedirectUI {
  SESSION_DETAILS = 'showSessionDetails',
  WORKSHOP_REGISTRANTS = 'showWorkshopRegistrants',
  WORKSHOP_CHILD_CARE_REQUIREMENTS = 'showWorkshopChildcareRequirements',
}
