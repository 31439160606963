import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import ThemeWrapper from 'theme/ThemeWrapper';
import { RedirectLayer } from 'components/Shared/RedirectLayer';
import ErrorBoundary from './ErrorBoundary';
import { store } from './redux/store';
import RoutesPage from './routes';

function App() {
  return (
    <ErrorBoundary>
      <Provider store={store}>
        <ThemeWrapper>
          <BrowserRouter>
            <RedirectLayer>
              <RoutesPage />
            </RedirectLayer>
          </BrowserRouter>
        </ThemeWrapper>
      </Provider>
    </ErrorBoundary>
  );
}

export default App;
