/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from 'react';

export type NavBarRouteType = {
  path: string;
  title?: string;
};

export type RouteType = {
  component: FC<any>;
  path: string;
  title?: string;
};

export enum RoutesPaths {
  SESSIONS_ATTENDANCE_LIST = 'session-attendance-list',
  ALL_ATTENDANCE_LIST = 'all-attendance-list',
  CHILDCARE_LIST = 'child-care-list',
  PAGE_NOT_FOUND = '404',
}
