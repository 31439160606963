import { customScrollbarStyle } from '@idaho-aeyc/ui-kit';
import { generateSpaceClassNames } from 'helpers/utils';
import { createGlobalStyle } from 'styled-components';

const spaces = [0, 4, 8, 12, 16, 20, 24, 28, 32, 36, 40];

export const GenericStyles = createGlobalStyle`
${() => generateSpaceClassNames(spaces)}

${customScrollbarStyle}

  .text-underline {
    text-decoration: underline;
  }
  .text-left {
    text-align: left;
  }
  .text-right {
    text-align: right;
  }
  .text-center {
    text-align: center;
  }
  .d-inline-block {
    display: inline-block;
  }
  .flex-column {
    flex-direction: column;
  }
.d-block {
  display: block;
}
  .h-100 {
    height: 100%;
  }
  .w-100 {
    width: 100%;
  }

  .mr-auto {
    margin-right: auto
  }
  .ml-auto {
    margin-left: auto
  }

  .pointer {
    cursor: pointer;
  }

  .no-border {
    border:none !important;
  }
  .flex-1 {
    flex-grow: 1;
  }

  .pointer-event-none {
    pointer-events: none;
  } 

  .flex-direction-column {
    flex-direction: column;
  }
  
  // TODO: NONA | MARTIROS find a way to import dropdown global styles frim ui-kit
.ant-dropdown-menu-item-group-title {
  font-weight: ${({ theme }) => theme.fontWeightMedium};
  color: ${({ theme }) => theme.colorBlackDescription}
}
.ant-table-filter-dropdown-btns {
    gap: ${({ theme }) => theme.paddingXs}px;
    .ant-btn {
      width: 50%;
      border-radius: ${({ theme }) => theme.buttonBorderRadiusLg}px;
      font-weight: ${({ theme }) => theme.buttonFontWeightMd};
      box-shadow:none;
      min-height: 32px;
    }
    .ant-btn-link {
      color: ${({ theme }) => theme.colorBlackPrimary};
      border: ${({ theme }) => theme.buttonBorderLg}
      ${({ theme }) => theme.buttonColorPrimaryNormalBg} ;
       &:disabled { 
          // styled as serach filter reset button
        color: ${({ theme }) => theme.buttonColorPrimaryDisabledText} ;
        background-color: ${({ theme }) =>
          theme.buttonColorPrimaryDisabledBg} ; 
          border-color: ${({ theme }) => theme.buttonColorPrimaryDisabledBg};
      }
    }
  }

  // notification styling
  .ant-notification-notice {
    &&.custom-notification {
        padding: 16px;
        border-radius: ${({ theme }) => theme.notificationBorderRadius}px;
        background-color: ${({ theme }) => theme.notificationColorBg};
        .ant-notification-notice-content {
          padding-right: 16px;
        }
        .ant-notification-notice-icon {
            display: none
        }
        .ant-notification-notice-message {
          margin-inline-start: 0;
          margin-bottom: 0;
          color: ${({ theme }) => theme.colorWhitePrimary};
        }
        .ant-notification-notice-close {
          top: 16px;
        }
    }
  }

`;
