import React, { FC, ReactElement, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'redux/store';

import appSlice from 'redux/slices/app.slice';
import { ERedirectDB } from 'data/types/redirect.types';
import { redirectsMapper } from 'data/mappers/redirectMapper';
import { redirectConverter } from 'data/convertors/redirect.converters';

export const RedirectLayer: FC<{
  children: ReactElement;
}> = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  useEffect(() => {
    const actionType = redirectConverter.fromDb(
      searchParams.get('action') as ERedirectDB,
    );
    const id = searchParams.get('id');
    const token = searchParams.get('token');
    if (token) {
      dispatch(appSlice.actions.setUserToken(token));
    }
    // else {
    //   dispatch(appSlice.actions.deleteUserToken());
    // }
    if (actionType) {
      const notificationObj = redirectsMapper[actionType];
      let { url } = notificationObj;
      if (id) {
        url += `/${id}`;
      }
      if (!notificationObj.private) {
        navigate(url, { state: searchParams.toString() });
        return;
      }
      navigate(url, { state: searchParams.toString() });
      // this link will be set in private routes
      dispatch(appSlice.actions.setCurrentNotificationUrl(url));
    }
  }, []);

  return <>{children}</>;
};
