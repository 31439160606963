import { styled } from 'styled-components';
import { Content } from '@idaho-aeyc/ui-kit';
import { Row } from 'components/Base/Grid';

export const SLayoutWrapper = styled.div`
  min-height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colorWhiteBgLayout};
  * {
    box-sizing: border-box;
  }
`;

export const SContent = styled(Content)`
  position: relative;
  height: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`;

export const SMainContent = styled(Row)`
  position: relative;
  height: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: 16px;
  background-color: ${({ theme }) => theme.colorWhitePrimary};
`;
