import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslate from './texts/en_texts.json';
import esTranslate from './texts/es_texts.json';

const resources = {
  en: {
    translation: enTranslate,
  },
  es: {
    translation: esTranslate,
  },
};

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
