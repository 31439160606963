import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { FC, Suspense, useEffect } from 'react';
import Layout from 'components/Base/Layout';
import appSlice from 'redux/slices/app.slice';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { appRoutes } from './RoutesData';
import { RoutesPaths } from './Routes.types';
import {migrateCleanUpStorage} from "../helpers/storageMigrations";

const PublicRoutes: FC = () => {
  migrateCleanUpStorage();
  const navigate = useNavigate();
  const redirectLink = useAppSelector(
    state => state.appSlice.currentNotificationUrl,
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (redirectLink) {
      navigate(redirectLink);
      dispatch(appSlice.actions.setCurrentNotificationUrl(''));
    }
  }, []);

  return (
    <Suspense fallback={<Layout />}>
      <Routes>
        <Route element={<Layout />}>
          <Route
            path="/"
            element={<Navigate replace to={appRoutes[0].path} />}
          />
          {appRoutes.map(({ path, component }) => {
            const Component = component;
            return (
              <Route key={path} path={`${path}`} element={<Component />} />
            );
          })}
          <Route
            path="*"
            element={<Navigate to={RoutesPaths.PAGE_NOT_FOUND} />}
          />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default PublicRoutes;
