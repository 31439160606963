import { appLocalStorage, EStorageNames } from './storage';

export const migrateCleanUpStorage = () => {
  const cleanUpStorage = appLocalStorage.getItem(
    EStorageNames.cleanUpStorage_V1,
  );

  if (cleanUpStorage) return;

  appLocalStorage.removeItem(EStorageNames.filters);
  appLocalStorage.removeItem(EStorageNames.tableSettings);

  appLocalStorage.setItem(EStorageNames.cleanUpStorage_V1, true);
};
