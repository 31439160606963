export enum ESortDirection {
  ascend = 'ascend',
  descend = 'descend',
}

export enum EAgeGroup {
  ZERO_TO_ONE = 'ZERO_TO_ONE',
  ONE_TO_TWO = 'ONE_TO_TWO',
  TWO_TO_THREE = 'TWO_TO_THREE',
  TWO_TO_FIVE = 'TWO_TO_FIVE',
  THREE_TO_FOUR = 'THREE_TO_FOUR',
  FOUR_TO_FIVE = 'FOUR_TO_FIVE',
  THREE_TO_FIVE = 'THREE_TO_FIVE',
}

export enum EWorkshopType {
  READY_FOR_KINDERGARTEN = 'READY_FOR_KINDERGARTEN',
  READY_FOR_MATH = 'READY_FOR_MATH',
  READY_FOR_KINDERGARTEN_SOCIAL_EMOTIONAL = 'READY_FOR_KINDERGARTEN_SOCIAL_EMOTIONAL',
  LEARNING_CHILD_CARE_AND_PRESCHOOL_PROGRAMS = 'LEARNING_CHILD_CARE_AND_PRESCHOOL_PROGRAMS',
  CHILD_CARE_AND_PRESCHOOL_PROGRAMS_PART_1 = 'CHILD_CARE_AND_PRESCHOOL_PROGRAMS_PART_1',
  CHILD_CARE_AND_PRESCHOOL_PROGRAMS_PART_2 = 'CHILD_CARE_AND_PRESCHOOL_PROGRAMS_PART_2',
}

export enum EWorkshopPartType {
  STANDALONE = 'STANDALONE',
  PART_ONE = 'PART_ONE',
  PART_TWO = 'PART_TWO',
  PART_THREE = 'PART_THREE',
}

export enum EAttendanceType {
  IN_PERSON = 'IN_PERSON',
  VIRTUAL = 'VIRTUAL',
}

export type TErrorField = {
  name: string[];
  errors: string[];
  warnings: string[];
};

export type TValidateFieldsError = {
  errorFields: TErrorField[];
  outOfDate: boolean;
};

export type TSortParams = {
  sortField?: string;
  sortDirection?: ESortDirection;
};

export type TRtkErrorType = {
  code?: number;
  data: {
    message: string;
  };
  status?: number;
} | null;

export enum ELanguage {
  ENGLISH = 'ENGLISH',
  SPANISH = 'SPANISH',
}

export enum EI18nLanguage {
  ENGLISH = 'en',
  SPANISH = 'es',
}
