import { Suspense, FC } from 'react';
import { Routes, Route } from 'react-router-dom';

import PublicRoutes from './PublicRoutes';

const RoutesPage: FC = () => {
  return (
    <Suspense fallback={false}>
      <Routes>
        <Route path="/*" element={<PublicRoutes />} />
      </Routes>
    </Suspense>
  );
};
export default RoutesPage;
