import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { TAppSliceState } from '../slices/app.slice';

const baseUrl = `${process.env.REACT_APP_HOSTNAME}/api/v1`;

export const emptySplitApi = (reducerPath: string, tagTypes?: string[]) =>
  createApi({
    reducerPath,
    tagTypes: tagTypes || [],
    baseQuery: fetchBaseQuery({
      baseUrl,
      prepareHeaders: async (headers, { getState }) => {
        const { userToken } = (getState() as { appSlice: TAppSliceState })
          .appSlice;
        if (userToken) {
          headers.set('x-user-token', userToken);
        } else {
          headers.delete('x-user-token');
        }
        return headers;
      },
    }),

    endpoints: () => ({}),
  });
