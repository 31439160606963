import { lazy } from 'react';
import { RoutesPaths, RouteType } from './Routes.types';

const SessionAttendanceList = lazy(
  () => import('pages/public/SessionAttendanceList'),
);
const TotalAttendanceList = lazy(
  () => import('pages/public/TotalAttendanceList'),
);
const ChildCareList = lazy(() => import('pages/public/ChildCareList'));

const PageNotFound = lazy(() => import('pages/public/PageNotFound'));

export const appRoutes: RouteType[] = [
  {
    title: '404',
    component: PageNotFound,
    path: RoutesPaths.PAGE_NOT_FOUND,
  },
  {
    component: SessionAttendanceList,
    path: RoutesPaths.SESSIONS_ATTENDANCE_LIST,
  },
  {
    component: TotalAttendanceList,
    path: RoutesPaths.ALL_ATTENDANCE_LIST,
  },
  {
    component: ChildCareList,
    path: RoutesPaths.CHILDCARE_LIST,
  },
];
