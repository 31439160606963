import {
  TAllAttendanceListDataDB,
  TAllAttendanceListDataUI,
  TAttendanceUpdateUI,
  TGetAllAttendanceList,
  TGetChildCareDataDB,
  TGetChildCareDataUI,
  TGetChildCareList,
  TSessionAttendanceListDataDB,
  TSessionAttendanceListDataUI,
} from 'data/types/session.types';
import { ERegistrantsUrls } from 'data/urls/registrants.url';
import {
  getAllAttendanceListDataConverter,
  getChildCareListDataConverter,
  getSessionAttendanceListDataConverter,
  updateAttendanceConverter,
} from 'data/convertors/session.convertor';
import { emptySplitApi } from '../helpers/slice.helpers';

const baseUrl = `${ERegistrantsUrls.HOST_APP}/${ERegistrantsUrls.WORKSHOPS}`;

export const registrantsApi = emptySplitApi('registrantsApi', [
  'session-attendance-list',
  'all-attendance-list',
  'child-care-list',
]).injectEndpoints({
  endpoints: build => ({
    getSessionAttendanceList: build.query<TSessionAttendanceListDataUI, string>(
      {
        query(sessionId) {
          return {
            url: `${baseUrl}/${ERegistrantsUrls.SESSIONS}/${sessionId}`,
            method: 'GET',
          };
        },
        providesTags: ['session-attendance-list'],
        transformResponse: (data: TSessionAttendanceListDataDB) =>
          getSessionAttendanceListDataConverter.fromDb(data),
      },
    ),
    getAllAttendanceList: build.query<
      TAllAttendanceListDataUI,
      TGetAllAttendanceList
    >({
      query(params) {
        return {
          url: `${baseUrl}/${ERegistrantsUrls.REGISTRANTS}`,
          method: 'GET',
          params,
        };
      },
      providesTags: ['all-attendance-list'],
      transformResponse: (data: TAllAttendanceListDataDB) =>
        getAllAttendanceListDataConverter.fromDb(data),
    }),
    getChildCareList: build.query<TGetChildCareDataUI, TGetChildCareList>({
      query(workshopId) {
        return {
          url: `${baseUrl}/${ERegistrantsUrls.REGISTRANTS}/${ERegistrantsUrls.CHILDCARE}`,
          method: 'GET',
          params: workshopId,
        };
      },
      providesTags: ['child-care-list'],
      transformResponse: (data: TGetChildCareDataDB) =>
        getChildCareListDataConverter.fromDb(data),
    }),
    updateAttendance: build.mutation<void, TAttendanceUpdateUI>({
      query(body) {
        return {
          url: `${baseUrl}/${ERegistrantsUrls.REGISTRANTS}/${ERegistrantsUrls.ATTENDANCE}`,
          method: 'PATCH',
          body: updateAttendanceConverter.toDb(body),
        };
      },
      invalidatesTags: ['session-attendance-list', 'all-attendance-list'],
    }),
  }),
});

export const {
  useGetSessionAttendanceListQuery,
  useGetAllAttendanceListQuery,
  useGetChildCareListQuery,
  useUpdateAttendanceMutation,
} = registrantsApi;
