import { TFetchConverter } from 'data/types/converter.types';
import { ERedirectDB, ERedirectUI } from 'data/types/redirect.types';

export const redirectConverter: TFetchConverter<ERedirectUI, ERedirectDB> = {
  fromDb: data => {
    const dataMapper: { [key in ERedirectDB]: ERedirectUI } = {
      [ERedirectDB.SESSION_DETAILS]: ERedirectUI.SESSION_DETAILS,
      [ERedirectDB.WORKSHOP_REGISTRANTS]: ERedirectUI.WORKSHOP_REGISTRANTS,
      [ERedirectDB.WORKSHOP_CHILD_CARE_REQUIREMENTS]:
        ERedirectUI.WORKSHOP_CHILD_CARE_REQUIREMENTS,
    };
    return dataMapper[data];
  },
};
